@import './_variables.scss';

button {
  border-radius: 4px;
  min-width: 100px;
  padding: 2px 16px;
  background: $white;
  cursor: pointer;
  max-height: 32px;
}

.button--outline {
  background: $white;
  border: 1px solid $blue;
  p {
    color: $blue;
  }
}

.button--outlineSelected {
  background: $white;
  border: 1px solid $green;
  p {
    color: $green;
  }
}

.button--primary {
  background: $blue;
  border: 1px solid $blue;
  p {
    color: $white;
  }
}

.button--small {
  max-height: 30px;
  p {
    font-size: 14px;
    line-height: 18px;
  }
}

.button--verySmall {
  max-height: 22px;
  min-width: 40px;
  p {
    font-size: 12px;
    line-height: 14px;
  }
}

.button--medium {
  padding: 4px 30px;
  max-height: 40px;
  p {
    font-size: 18px;
    line-height: 22px;
  }
}

.button--large {
  padding: 6px 40px;
  max-height: 60px;
  p {
    font-size: 22px;
    line-height: 26px;
  }
}

.button--disabled {
  background-color: $veryLightGrey !important;
  color: $darkGrey !important;
  p {
    color: $darkGrey !important;
  }
}
