@import './variables.scss';

.rootOffice__container {
  border-bottom: 2px solid $black;
}

.rootOffice__header {
  display: flex;
  border-bottom: 1px solid $mildGrey;
  padding: 8px 0;
}

.floors__list {
  padding-left: 16px;
}

.floor__container {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  border-bottom: 1px solid $mildGrey;
  .container__textView {
    width: 250px;
  }
  .container__textView input {
    font-size: 13px;
  }
  &:last-child {
    border-bottom: 0;
  }
}
