@import '../components/variables';

.report__page {
  margin: 64px 0;
}

.report__link {
  width: 300px;
  height: 150px;
  border-radius: 12px;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  p {
    font-size: 18px;
    font-weight: 600;
  }
  &:hover {
    background-color: $lightGrey;
  }
}
